/* eslint-disable max-lines */
import React, { Fragment, MouseEvent, useContext, useState } from 'react';
import { Box, Button, Divider, Icon, Menu, Typography, MenuItem } from 'components/atoms';
import RightAppBar from 'components/organisms/appBar/RightAppBar';
import { AppLogo } from 'components/atoms';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { PATH } from 'paths';
import { ButtonSize } from 'components/atoms/Button';
import { IAppBarData } from 'models/AppBarData';
import PricingSideBar from 'components/organisms/pricing/PricingSideBar';
import { AuthContext } from 'contexts/AuthContext';
import { useLogout } from 'api/queries/auth';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { CompanyStatus } from 'models/api/management';
import { Dialogs } from 'models/api/auth';
import { CALCULATOR_STORAGE_KEY, TUTORIAL_VIDEO } from 'consts/storage.const';
import { GlobalModalsContext } from 'contexts/GlobalModalsProvider';
import LeftAppBar from 'components/organisms/appBar/LeftAppBar';
import MobileAppBar from 'components/organisms/appBar/MobileAppBar';
import texts from 'texts.json';
import VideoTutorialDialog from 'components/molecules/VideoTutorialDialog';

const DefaultAppBar = () => {
    const { personalArea, videoTutorial } = texts.appBar;
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const isPricing = location.pathname.startsWith(`/${PATH.PRICING}`);

    const [, setCalculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);
    const [showTutorialAgain, setShowTutorialAgain] = useLocalStorage(TUTORIAL_VIDEO, true);
    const { onOpen: openRemainingSearchesModal } = useContext(GlobalModalsContext);
    const { user, handleLogout: onLogout, setShowDialog } = useContext(AuthContext);
    const { mutate: mutateLogout } = useLogout();

    const isCompany = !!user?.user?.owner_company || !!user?.user?.owner_company_employee;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openVideo, setOpenVideo] = useState<boolean>(showTutorialAgain);

    const openAuthMenu = !!anchorEl;
    const calculatorPath = `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;
    const status = user?.user?.company_status;
    const isCalculatorButtonDisabled = isCompany && status !== CompanyStatus.APPROVED;

    const handleOpenAuthMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAuthMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        mutateLogout(undefined, {
            onSuccess: () => {
                onLogout();
                setCalculator(null);
                navigate(calculatorPath);
            },
        });
        handleCloseAuthMenu();
    };

    const onNavigateToCalculator = () => {
        if (location.pathname === calculatorPath) {
            return;
        }
        if (!user?.user) {
            navigate(calculatorPath);
        } else {
            openRemainingSearchesModal();
        }
    };

    const data: {
        [key: string]: IAppBarData[];
    } = {
        leftSection: [
            // { title: 'המדריך הסולארי', link: '', icon: <Icon.Book /> },
            { title: 'כמה הגג שלי שווה?', link: calculatorPath },
            {
                title: user?.token ? user?.user?.fullname : 'הרשמה/ כניסה',
                link: '',
                icon: <Icon.Account />,
                onClick: user?.token ? handleOpenAuthMenu : () => setShowDialog(Dialogs.LOGIN),
            },
        ],
        rightSection: [
            // { title: 'בעלי נכסים', link: `/${PATH.CALCULATOR}/${PATH.ADDRESS}` },
            {
                title: personalArea,
                link: `/${PATH.PRICING}/${PATH.PRICING_MY_OFFERS}`,
                render: !!user?.user,
            },
            {
                title: videoTutorial,
                render: !!user?.user,
                link: '',
                icon: <Icon.Camera />,
                onClick: () => setOpenVideo(true),
            },
            // { title: 'יזמים', link: `/${PATH.PRICING}` },
        ],
    };

    const [roofValue, login] = data.leftSection;

    return (
        <>
            {isDesktop ? (
                <Box height='90px' px='158px' display='flex' alignItems='center' justifyContent='space-between'>
                    <RightAppBar data={data.rightSection} />
                    <AppLogo onClick={() => (isCalculatorButtonDisabled ? undefined : onNavigateToCalculator())} />
                    <LeftAppBar
                        data={data.leftSection}
                        onNavigateToCalculator={onNavigateToCalculator}
                        isCalculatorButtonDisabled={isCalculatorButtonDisabled}
                    />
                </Box>
            ) : (
                <MobileAppBar handleOpenAuthMenu={handleOpenAuthMenu} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
                    {isPricing ? (
                        <PricingSideBar onClick={() => setOpenDrawer(false)} />
                    ) : (
                        <Box mt='40px'>
                            <Box px='30px'>
                                <AppLogo
                                    onClick={() =>
                                        isCalculatorButtonDisabled ? undefined : navigate(`/${PATH.CALCULATOR}/${PATH.ADDRESS}`)
                                    }
                                />
                            </Box>
                            <Box display='flex' flexDirection='column' mt='40px'>
                                {data.rightSection.map((item, index) => {
                                    return item.render ? (
                                        <Fragment key={index}>
                                            <Box display='flex' alignItems='center' gap={10} px='30px'>
                                                {item.icon && item.icon}
                                                <Button.Text
                                                    onClick={(e) => {
                                                        if (item?.onClick) {
                                                            item.onClick(e);
                                                        } else {
                                                            navigate(item.link);
                                                        }
                                                        setOpenDrawer(false);
                                                    }}
                                                >
                                                    <Typography.Body3 font={theme.fontFamilies.regular}>{item.title}</Typography.Body3>
                                                </Button.Text>
                                            </Box>
                                            <Box my='20px' width='100%'>
                                                <Divider />
                                            </Box>
                                        </Fragment>
                                    ) : null;
                                })}
                                <Box px='30px'>
                                    <Button.Text onClick={openRemainingSearchesModal} disabled={isCalculatorButtonDisabled}>
                                        <Typography.Body3 font={theme.fontFamilies.regular} color={theme.palette.primary.purple}>
                                            {roofValue.title}
                                        </Typography.Body3>
                                    </Button.Text>
                                </Box>
                            </Box>
                            <Box position='absolute' bottom={0} left={0} width='100%'>
                                <Button.Mobile
                                    size={ButtonSize.LARGE}
                                    backgroundColor={theme.palette.primary.greyBackground}
                                    onClick={() => setShowDialog(Dialogs.LOGIN)}
                                    justifyContent='start'
                                >
                                    <Box display='flex' alignItems='center' gap={10} px='24px'>
                                        {login.icon}
                                        <Typography.Body3 font={theme.fontFamilies.regular}>{login.title}</Typography.Body3>
                                    </Box>
                                </Button.Mobile>
                            </Box>
                        </Box>
                    )}
                </MobileAppBar>
            )}
            <Menu
                open={openAuthMenu}
                anchorEl={anchorEl}
                onClose={handleCloseAuthMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {user ? (
                    <MenuItem
                        onClick={() => {
                            handleLogout();
                        }}
                    >
                        התנתק
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={() => {
                            setShowDialog(Dialogs.LOGIN);
                            handleCloseAuthMenu();
                        }}
                    >
                        התחבר
                    </MenuItem>
                )}
            </Menu>
            {!!user?.user && openVideo && (
                <VideoTutorialDialog
                    showAgain={showTutorialAgain}
                    onShowAgainChange={setShowTutorialAgain}
                    open={openVideo}
                    onClose={() => setOpenVideo(false)}
                />
            )}
        </>
    );
};

export default DefaultAppBar;
