import React, { FC, useContext } from 'react';
import { Box, Button, Icon, Typography } from 'components/atoms';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';
import TabSideBar from 'components/organisms/pricing/TabSideBar';
import { AppLogo } from 'components/atoms';
import { CompanyStatus, CompanyType } from 'models/api/management';
import texts from 'texts.json';
import useAuth from 'hooks/useAuth.hook';
import { useGetRemainingProjects } from 'api/queries/common';
import { useTheme } from '@mui/material';
import { GlobalModalsContext } from 'contexts/GlobalModalsProvider';

interface ICompanyType {
    isConstractor: boolean;
    isEntrepreneur: boolean;
}

const tabs = (isApproved: boolean, isCompany: boolean, isCompanyEmployee: boolean, companyType: ICompanyType) => [
    {
        link: PATH.PRICING_MY_OFFERS,
        title: texts.pricing.sideBar.navigation.myOffers,
        icon: <Icon.Profile />,
        disabled: false,
        render: true,
    },
    // {
    //     link: PATH.PERSONAL_AREA,
    //     title: texts.pricing.sideBar.navigation.personalArea,
    //     icon: <Icon.AccountColorful />,
    //     disabled: false,
    // },
    {
        link: `../${PATH.CALCULATOR}/${PATH.ADDRESS}`,
        title:
            isCompany || isCompanyEmployee ? texts.pricing.sideBar.navigation.quotation : texts.pricing.sideBar.navigation.quotationAgent,
        icon: <Icon.TagColorful />,
        disabled: (isCompany || isCompanyEmployee) && !isApproved,
        render: true,
    },
    {
        link: PATH.PRICING_CONTRACTOR,
        title: texts.pricing.sideBar.navigation.pricingContractor,
        icon: <Icon.Laborer />,
        disabled: isCompanyEmployee || !companyType?.isConstractor || !isApproved,
        render: isCompany || isCompanyEmployee,
    },
    {
        link: PATH.PRICING_ENTREPRENEUR,
        title: texts.pricing.sideBar.navigation.pricingEntrepreneur,
        icon: <Icon.Idea />,
        disabled: isCompanyEmployee || !companyType?.isEntrepreneur || !isApproved,
        render: isCompany || isCompanyEmployee,
    },
    {
        link: PATH.MANAGEMENT,
        title: texts.pricing.sideBar.navigation.management,
        icon: <Icon.Manage />,
        disabled: isCompanyEmployee,
        render: isCompany || isCompanyEmployee,
    },
    // {
    //     link: PATH.PRICING_TRANSFORMERS,
    //     title: 'מחירון ממירים',
    //     icon: <Icon.Transfer />,
    // },
];

interface IProps {
    onClick?: () => void;
}

const PricingSideBar: FC<IProps> = ({ onClick }) => {
    const theme = useTheme();
    const [, , path] = useLocation().pathname.split('/');
    const { isCompany, isCompanyEmployee, user } = useAuth();
    const { setContactUsModal } = useContext(GlobalModalsContext);
    const { data: remainingSearches } = useGetRemainingProjects(true);

    const remainingProjects = remainingSearches?.data.item.remaining_projects || 0;
    const isRemainingProjects = remainingProjects === 'UNLIMITED' || remainingProjects > 0;
    const status = user?.user?.company_status;
    const isCompanyApproved = status === CompanyStatus.APPROVED;
    const companyType = {
        isConstractor: user?.user?.company_type?.includes(CompanyType.CONSTRACTOR) || false,
        isEntrepreneur: user?.user?.company_type?.includes(CompanyType.ENTREPRENEUR) || false,
    };

    const {
        pricing: {
            sideBar: {
                footer: {
                    logo: { label, path: logoPath },
                },
            },
        },
    } = texts;

    return (
        <Box pt={'60px'} height='100%' bgcolor='primary.white' display='flex' flexDirection='column'>
            <Box textAlign='center'>
                <AppLogo onClick={() => {}} />
            </Box>
            <Box p={{ md: '30px' }}>
                {tabs(isCompanyApproved, isCompany, isCompanyEmployee, companyType).map(
                    (tab, index) =>
                        tab.render && <TabSideBar tab={tab} isActive={path === tab.link} index={index} key={index} onClick={onClick} />
                )}
            </Box>
            {remainingSearches && (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    rowGap={'20px'}
                    mt={{ xs: '20px', md: 'unset' }}
                >
                    <Typography.Body3>{`נותרו לך עוד ${
                        remainingProjects === 'UNLIMITED' ? 'ללא גבלה' : remainingProjects
                    } איתורי נכס`}</Typography.Body3>
                    {!isRemainingProjects && (
                        <Button.Text onClick={() => setContactUsModal(true)}>
                            <Typography.Body4 textUnderline color={theme.palette.primary.purple}>
                                לרכישת בדיקות נוספות
                            </Typography.Body4>
                        </Button.Text>
                    )}
                </Box>
            )}
            <Box mt='auto' mb='20px' textAlign='center'>
                <Typography.Body3>{label}</Typography.Body3>
                <br />
                <img width='70%' src={logoPath} alt='לוגו משרד האנרגיה' />
            </Box>
        </Box>
    );
};

export default PricingSideBar;
