/* eslint-disable max-lines */
import { styled } from '@mui/material';
import { Box, Grid } from 'components/atoms';
import environmentalDataBackground from 'assets/images/environmentalDataBackground.png';
import { useGetProjectCalculator } from 'api/queries/calculator';
import { PATH } from 'paths';
import { useNavigate } from 'react-router';
import SystemData from 'pages/results/SystemData';
import PaymentModel from 'pages/results/PaymentModel';
import EnvironmentalData from 'pages/results/EnvironmentalData';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useCreateProjectQuotations } from 'api/queries/offers';
import ProjectClientNameModal from 'components/organisms/results/ProjectClientNameModal';
import { CompanyType } from 'models/api/management';
import { ContractTypeModel } from 'models/common';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import Header from 'pages/results/Header';
import PanelsLayoutModal from 'components/organisms/PanelsLayoutModal';
import { getProjectData } from 'data/projectData';
import { EProjectStatusType, IDownloadCompanyFileFieldsReq } from 'models/api/common';
import { isDefaultQuotation } from 'utils/isDefaultQuotation';
import useCompanyQuotationDownload from 'hooks/useCompanyQuotationDownload';
import useAgentQuotationDownload from 'hooks/useAgentQuotationDownload';
import useBackButtonListener from 'hooks/useBackButtonListener';
import { AuthContext } from 'contexts/AuthContext';
import { GlobalModalsContext } from 'contexts/GlobalModalsProvider';
import { useLocation } from 'react-router-dom';
import ResultsButtons from 'pages/results/ResultsButtons';

const MobileButtonsWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px 10px',
    rowGap: '12px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.grey,
}));

const sx = {
    environmentalData: {
        backgroundImage: `url('${environmentalDataBackground}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
};

const Results = () => {
    const isDesktop = useIsDesktop();
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useContext(AuthContext);
    const { onOpen: onOpenRemainingSearchesModal } = useContext(GlobalModalsContext);

    const [calculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);

    const projectId = calculator?.projectId;
    const isCompany = !!user?.user.owner_company || !!user?.user.owner_company_employee;
    const isAgent = !!user?.user.owner_agent;
    const isClient = !!user?.user.owner_client;
    const companyName = user?.user.fullname;
    const isFromMyOffersPage = location.state?.from === PATH.PRICING_MY_OFFERS;
    const companyType = user?.user?.company_type;

    const { data: projectData } = useGetProjectCalculator(projectId, !!projectId);
    const { mutate: createProjectQuotations } = useCreateProjectQuotations();

    const [quotationType, setQuotationType] = useState<IDownloadCompanyFileFieldsReq | null>(null);
    const [selectedTab, setSelectedTab] = useState<ContractTypeModel>(ContractTypeModel.PURCHASE);
    const [clientName, setClientName] = useState('');
    const [isClientNameModal, setIsClientNameModal] = useState(false);

    const isProjectEditable =
        projectData?.status === EProjectStatusType.PROJECT_STATUS_DRAFT || projectData?.status === EProjectStatusType.PROJECT_STATUS_NEW;

    const { onDownloadCompanyQuotation } = useCompanyQuotationDownload(projectId, selectedTab, companyName, isProjectEditable, {
        RENT: projectData?.elected_entrepreneur_quotations?.[0],
        PURCHASE: projectData?.elected_quotations?.[0],
    });
    const { onDownloadAgentQuotation } = useAgentQuotationDownload(projectId, selectedTab, companyName, {
        RENT: projectData?.default_entrepreneur_quotation,
        PURCHASE: projectData?.default_quotation,
    });

    const chooseDefaultTab = () => {
        if (companyType) {
            const isConstractor = companyType?.some((item) => item === CompanyType.CONSTRACTOR);
            setSelectedTab(isConstractor ? ContractTypeModel.PURCHASE : ContractTypeModel.RENT);
        }
    };

    useEffect(chooseDefaultTab, [companyType]);

    const isCompanyQuotationLocked = isCompany && !isProjectEditable;

    const { purchaseData, rentData, systemData, environmentalData } = getProjectData(projectData);

    const onBackButtonEvent = useCallback(
        (e: any) => {
            e.preventDefault();
            onOpenRemainingSearchesModal();
        },
        [onOpenRemainingSearchesModal]
    );
    const isBackButtonListener = isCompanyQuotationLocked && !isFromMyOffersPage;
    useBackButtonListener(onBackButtonEvent, isBackButtonListener);

    const onClick = () => {
        if (isClient) {
            createProjectQuotations(projectId, {
                onSuccess: () => {
                    navigate(`/${PATH.CONTRACTOR_OFFERS}`);
                },
            });
            return;
        }
        if (clientName) {
            onQuotationDownload(clientName, IDownloadCompanyFileFieldsReq.QUOTATION_PDF);
        } else {
            setIsClientNameModal(true);
            setQuotationType(IDownloadCompanyFileFieldsReq.QUOTATION_PDF);
        }
    };

    const onFullQuotationDownload = () => {
        if (clientName) {
            onQuotationDownload(clientName, IDownloadCompanyFileFieldsReq.EXPANDED_QUOTATION_PDF);
        } else {
            setIsClientNameModal(true);
            setQuotationType(IDownloadCompanyFileFieldsReq.EXPANDED_QUOTATION_PDF);
        }
    };

    const onQuotationDownload = (name: string, quotationType: IDownloadCompanyFileFieldsReq) => {
        if (isCompany) {
            onDownloadCompanyQuotation(name, quotationType);
        } else if (isAgent) {
            onDownloadAgentQuotation(name, quotationType);
        }
    };

    const onProjectClientNameModalClose = () => {
        onQuotationDownload(clientName, quotationType!);
        setIsClientNameModal(false);
    };

    const onProjectClientNameModalSubmit = (name: string) => {
        if (!clientName) {
            setClientName(name);
        }
        onQuotationDownload(name, quotationType!);
        setIsClientNameModal(false);
    };

    const isAgentHasFullQuotation = isAgent && isDefaultQuotation(selectedTab, projectData);
    const isFullQuotation = isCompany || isAgentHasFullQuotation;
    const isCompanyQuotation =
        selectedTab === ContractTypeModel.PURCHASE ? projectData?.economic_model : projectData?.entrepreneur_economic_model;

    const isButtonDisabledForCompany = isCompany && !isCompanyQuotation;
    const isButtonDisabledForUser = (isAgent || isClient) && projectData?.power?.length && Number(projectData.power) === 0;
    const disabled = isButtonDisabledForCompany || !!isButtonDisabledForUser;

    const buttons = (
        <ResultsButtons
            isCompany={isCompany}
            disabled={disabled}
            isFullQuotation={isFullQuotation}
            selectedTab={selectedTab}
            onClick={onClick}
            onFullQuotationDownload={onFullQuotationDownload}
        />
    );

    if (!projectData) {
        return null;
    }

    return (
        <Box id='scrollable' overflow='auto' height='100%'>
            <Box width={{ md: '1140px', xs: '90%' }} margin='0 auto'>
                <Header address={calculator?.address} isCompanyQuotationUnlocked={!isCompanyQuotationLocked} />
                <Grid container spacing={{ xs: 8, md: 18 }} rowSpacing={{ xs: 10, md: 0 }} width='100%'>
                    <SystemData {...systemData} />
                </Grid>
                <Box display={'flex'} justifyContent={isDesktop ? 'end' : 'center'} mt={'12px'}>
                    <Box display={'flex'} width={isDesktop ? '160px' : '50%'}>
                        <PanelsLayoutModal roof_preview={projectData.roof_preview} />
                    </Box>
                </Box>
                <Box pt='60px' margin='0 auto'>
                    <PaymentModel
                        purchaseData={purchaseData}
                        rentData={rentData}
                        isCompany={isCompany}
                        companyType={companyType}
                        selectedTab={selectedTab}
                        setSelectedTab={(value: ContractTypeModel) => {
                            setSelectedTab(value);
                        }}
                    />
                </Box>
                {isDesktop && (
                    <Box display={'flex'} mx='auto' p='25px 0 50px 0' alignItems={'center'} justifyContent={'center'} columnGap={'20px'}>
                        {buttons}
                    </Box>
                )}
            </Box>
            <Box sx={sx.environmentalData}>
                <EnvironmentalData {...environmentalData} />
            </Box>
            {!isDesktop && <MobileButtonsWrapper>{buttons}</MobileButtonsWrapper>}
            {isClientNameModal && (
                <ProjectClientNameModal
                    open={!!quotationType}
                    onClose={onProjectClientNameModalClose}
                    onSubmit={(clientName: string) => {
                        onProjectClientNameModalSubmit(clientName);
                    }}
                />
            )}
        </Box>
    );
};

export default Results;
