import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';

const useAuth = () => {
    const { user } = useContext(AuthContext);

    const isCompany = !!user?.user.owner_company;
    const isCompanyEmployee = !!user?.user.owner_company_employee;
    const isAgent = !!user?.user.owner_agent;
    const isClient = !!user?.user.owner_client;

    return { user, isCompany, isAgent, isClient, isCompanyEmployee };
};

export default useAuth;
